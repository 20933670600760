var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"navigation-top"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_vm._m(0),_c('div',{staticClass:"side-nav",attrs:{"id":"side-menu"}},[_c('router-link',{attrs:{"to":{
          path: '/home'
        },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/home-menu.png")}}),_vm._v(" Dashboard ")]),_c('router-link',{attrs:{"to":{
          path: '/shifts'
        },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Shifts ")]),_c('router-link',{attrs:{"to":{
            path: '/premium-clients-order'
          },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Premium Clients Order ")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logOutSection.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("../../../public/images/clerk.png")}}),_vm._v(" Logout ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-area"},[_c('img',{attrs:{"src":require("../../../public/images/pms-logo.png")}})])
}]

export { render, staticRenderFns }