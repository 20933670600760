var render = function render(){var _vm=this,_c=_vm._self._c;return (
    typeof _vm.getUserData.user !== 'undefined' &&
      Object.keys(_vm.getUserData.user).length
  )?_c('div',{staticClass:"main-wrapper-area row"},[(
      _vm.getUserData.userRoles == 'super-admin'
    )?_c('super-admin-header-header-vue'):(
      _vm.getUserData.userRoles == 'admin'
    )?_c('admin-header-header-vue'):(
      _vm.getUserData.userRoles == 'supervisor'
    )?_c('supervisor-header-header-vue'):(
      _vm.getUserData.userRoles == 'cashier'
    )?_c('cashier-header-header-vue'):_c('default-header-vue')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }