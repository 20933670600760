<template>
  <header class="navigation-top">
    <div class="row">
      <div class="col-sm-12">
        <div class="logo-area">
          <img src="../../../public/images/pms-logo.png">
        </div>

        <div id="side-menu" class="side-nav">
          <router-link :to="{
            path: '/home'
          }" tag="a">
            <img src="../../../public/images/home-menu.png">
            Dashboard
          </router-link>

          <router-link :to="{
            path: '/petrol-station'
          }" tag="a">
            <img src="../../../public/images/station.png">
            Petrol Station
          </router-link>

          <router-link :to="{
            path: '/shifts'
          }" tag="a">
            <img src="../../../public/images/station.png">
            Shifts
          </router-link>
          <!-- Collapse 2 -->
          <router-link :to="{
            path: '/shift-sales'
          }" tag="a">
            <img src="../../../public/images/station.png">
            Shift Sale
          </router-link>
          <router-link :to="{
            path: '/orders'
          }" tag="a">
            <img src="../../../public/images/station.png">
            Vendor Orders
          </router-link>

          <router-link :to="{
              path: '/premium-clients-order'
            }" tag="a">
            <img src="../../../public/images/station.png">
            Premium Clients Order
          </router-link>

          <router-link :to="{
            path: '/tanks'
          }" tag="a">
            <img src="../../../public/images/station.png">
            Tanks
          </router-link>
          <router-link :to="{
            path: '/stocks'
          }" tag="a">
            <img src="../../../public/images/station.png">
            Stocks
          </router-link>

          <!-- Collapse 2 -->
          <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
             aria-controls="collapseExample">
            <img src="../../../public/images/station.png">
            User Management
          </a>
          <!-- Collapsed content -->
          <div class="collapse" id="collapseExample">
            <router-link :to="{
              path: '/admin'
            }" tag="a">
              <img src="../../../public/images/station.png">
              Admins
            </router-link>
            <router-link :to="{
              path: '/vendor'
            }" tag="a">
              <img src="../../../public/images/station.png">
              Vendor
            </router-link>

            <router-link :to="{
              path: '/premium-clients'
            }" tag="a">
              <img src="../../../public/images/station.png">
              Premium Clients
            </router-link>


            <router-link :to="{
              path: '/cashier'
            }" tag="a">
              <img src="../../../public/images/station.png">
              Cashier
            </router-link>
            <router-link :to="{
              path: '/supervisor'
            }" tag="a">
              <img src="../../../public/images/station.png">
              Supervisor
            </router-link>
          </div>


          <!-- Collapse 2 -->
          <a data-toggle="collapse" href="#financeCollapse" role="button" aria-expanded="false"
             aria-controls="financeCollapse">
            <img src="../../../public/images/station.png">
            Finance
          </a>
          <!-- Collapsed content -->
          <div class="collapse" id="financeCollapse">
            <router-link :to="{
              path: '#'
            }" tag="a">
              <img src="../../../public/images/station.png">
              Total Sales
            </router-link>
            <router-link :to="{
              path: '#'
            }" tag="a">
              <img src="../../../public/images/station.png">
              Transactions
            </router-link>

            <router-link :to="{
              path: '/expenses'
            }" tag="a">
              <img src="../../../public/images/station.png">
              Expense
            </router-link>
          </div>

          <a @click.prevent="logOutSection" href="#">
            <img src="../../../public/images/clerk.png">
            Logout
          </a>
        </div>
        <!-- Collapse 2 -->




      </div>


    </div>
    </div>
  </header>
</template>

<script>
// import settingsNav from "../admin-setting-nav";
export default {
  data() {
    return {
      pre: "",
      homepre: "",
      isShow: false,
      opened: false,
    };
  },
  components: {
    // settingsNav: settingsNav
  },
  computed: {
    getUserData() {
      return this.$store.state.auth;
    },
    getBreadCums() {
      return this.$router.app._route.meta;
    }
  },
  mounted() { },
  methods: {
    logOutSection() {
      this.$store.dispatch("generalLoader", {
        response: true
      });
      this.$store.dispatch("logOutSection");
    }
  }
};
</script>

<style lang="scss" scoped>
nav#navigator {
  width: 100%;

  .navbar ul[data-v-519b0f42] {
    margin: 0 0 0 0;
    list-style: none;
  }

  .navbar-nav {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}

.navbar {
  background-color: #3b5998;
  overflow: hidden;
  height: 63px;
}

.navbaropen {
  background-color: #3b5998;
  overflow: hidden;
  height: 63px;
  margin-left: 250px;
}

.navbar a {
  float: left;
  display: block;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
}

.navbar ul {
  margin: 8px 0 0 0;
  list-style: none;
}

div#side-menu {
  padding-top: 25px;

  a {
    vertical-align: -webkit-baseline-middle;
    font: normal normal normal 16px/27px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: table;
    width: 100%;

    &:hover {
      background-color: #0c52f1;
      font: normal normal normal 16px/27px  Segoe UI;
      letter-spacing: 0px;
      color: #FFFFFF;
    }

    &.router-link-active {
      background-color: #0c52f1;
      font: normal normal normal 16px/27px  Segoe UI;
      letter-spacing: 0px;
      color: #FFFFFF;
    }

    img {
      width: 19px;
      vertical-align: middle;
      margin-right: 11px;
    }
  }
}

.side-nav a {
  padding: 10px 10px 10px 15px;
  text-decoration: none;
  font-size: 15px;
  color: #ccc;
  display: block;
  transition: 0.3s;
  border-bottom: none;
  margin-bottom: 0;
}

.side-nav a:hover {
  color: #fff;
}


// New Header Styling :
.logo-area {
  text-align: center;
  padding-top: 35px;

  img {
    width: 70%;
    position: relative;
    left: -6px;
  }
}

div#collapseExample {
  padding-left: 20px;
}
</style>
